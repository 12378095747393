<template>
    <div class="position-relative">
        <div
            class="w-100 container-fluid"
            style="position: fixed; top: 0; left: 0; z-index: 1001"
            v-if="!historyBack"
        >
            <div class="row align-items-center py-2 bg-light">
                <div class="col-6 brand d-flex align-items-center">
                    <span style="line-height: 20px"
                        ><font-awesome-icon
                            :icon="['fas', 'crown']"
                            class="text-primary"
                        />
                        LIVE TV Pro
                    </span>
                </div>
                <div class="col-6 text-right settings">
                    <router-link
                        class="text-dark mr-4"
                        ref="search"
                        :to="{
                            name: 'search',
                            query: {
                                only_search: 1,
                                force_update: $route.query.force_update,
                            },
                        }"
                    >
                        <font-awesome-icon :icon="['fas', 'search']" />
                    </router-link>
                    <router-link
                        class="text-dark"
                        ref="settings"
                        :to="{
                            name: 'settings',
                            query: {
                                date: $route.query.date,
                                history_back: 1,
                                force_update: $route.query.force_update,
                            },
                        }"
                    >
                        <font-awesome-icon :icon="['fas', 'cog']" />
                    </router-link>
                </div>
            </div>
            <div class="row text-white" style="background: #000">
                <div class="col-12 n d-flex flex-nowrap justify-content-around">
                    <router-link
                        class="d-flex flex-column align-items-center"
                        ref="today"
                        :to="{ name: 'today' }"
                    >
                        <font-awesome-icon :icon="['fas', 'calendar-day']" />
                        <span>Heute</span>
                    </router-link>
                    <router-link
                        class="d-flex flex-column align-items-center"
                        ref="program"
                        :to="{ name: 'program' }"
                    >
                        <font-awesome-icon :icon="['far', 'list-alt']" />
                        <span>Programm</span>
                    </router-link>
                    <router-link
                        class="d-flex flex-column align-items-center"
                        ref="medialibrary"
                        :to="{ name: 'medialibrary' }"
                    >
                        <font-awesome-icon :icon="['fas', 'tv']" />
                        <span>Mediathek</span>
                    </router-link>
                    <router-link
                        class="d-flex flex-column align-items-center"
                        ref="mytv"
                        :to="{ name: 'tv' }"
                    >
                        <font-awesome-icon :icon="['fas', 'user']" />
                        <span>Mein TV</span>
                    </router-link>
                </div>
            </div>
        </div>

        <div
            class="w-100 container-fluid"
            style="position: fixed; top: 0; left: 0; z-index: 1001"
            v-if="historyBack"
        >
            <div class="row text-white" style="background: #000">
                <div
                    class="
                        col-12
                        d-flex
                        align-items-center
                        justify-content-between
                    "
                >
                    <div class="px-2 py-4 h5 mb-0" @click="back()">
                        <font-awesome-icon :icon="['fas', 'arrow-left']" />
                    </div>
                    <div v-if="$store.state.historyBackTitle">
                        {{ $store.state.historyBackTitle }}
                    </div>
                </div>
            </div>
        </div>
        <div
            class="container-fluid pt-2"
            :style="[
                historyBack
                    ? { 'margin-top': '70px' }
                    : { 'margin-top': '103px' },
            ]"
        >
            <!--<div id="log" class="border border-dark p-2"></div>-->
            <transition name="fade">
                <router-view></router-view>
            </transition>
        </div>
    </div>
</template>
<style lang="scss">
@import './scss/app.scss';
</style>
<script>
export default {
    data: function () {
        return {
            interval: null,
            updateIntervalMs: 10000,
            historyBack: false,
            recentModalIds: [],
            lastSyncAt: null,
            prevRouteName: null,
            fetchVersionInternval: null,
            version: 37,
        }
    },
    computed: {},
    watch: {
        $route(to, from) {
            if (
                this.$refs[from.name] !== undefined &&
                this.$refs[from.name].$el !== undefined &&
                this.$refs[from.name].$el !== null
            )
                this.$refs[from.name].$el.classList.remove('router-link-active')

            if (
                to.name == 'settings' ||
                to.name == 'live' ||
                to.name == 'p2015' ||
                to.name == 'p2200' ||
                to.name == 'bookmarks' ||
                to.name == 'recommendations' ||
                to.name == 'wm' ||
                to.name == 'search' ||
                to.name == 'search-wm' ||
                to.name == 'medialibrary-channels' ||
                to.name == 'medialibrary-recommendations' ||
                to.name == 'medialibrary-series' ||
                to.name == 'medialibrary-latest' ||
                to.name == 'medialibrary-movies' ||
                to.name == 'joyn'
            ) {
                this.historyBack = true
            } else {
                this.historyBack = false
            }
            if (from.name != to.name) {
                this.prevRouteName = from.name
            }
            this.$store.state.historyBackTitle = null
        },
    },
    methods: {
        onBackPressed() {
            if (this.recentModalIds.length) {
                this.$bvModal.hide(
                    this.recentModalIds[this.recentModalIds.length - 1]
                )
            }
        },
        back() {
            if (this.$route.name === 'wm') {
                this.$router.push({ path: '/' })
            } else {
                if (this.prevRouteName !== null) {
                    this.$router.push({ path: '/' + this.prevRouteName })
                } else {
                    this.$router.push({ path: '/today' })
                }
            }

            //this.$router.back()
        },
        u() {
            this.$store.commit('program/INCREMENT_COUNTER')
            this.$store.commit('series/INCREMENT_COUNTER')
        },
    },
    beforeMount() {
        if (
            this.$router.currentRoute.query.api_token &&
            this.$router.currentRoute.query.api_token.length == 32
        ) {
            localStorage.setItem(
                'api_token',
                this.$router.currentRoute.query.api_token
            )
            this.$store.state.auth.token =
                this.$router.currentRoute.query.api_token
        } else if (localStorage.getItem('api_token') !== null) {
            this.$store.state.auth.token = localStorage.getItem('api_token')
        } else {
            alert(
                'Es ist ein Fehler aufgetreten. Bitte versuche die App erneut zu starten.'
            )
        }

        if (
            this.$router.currentRoute.query.v &&
            this.$router.currentRoute.query.v.length > 0
        ) {
            localStorage.setItem(
                'app_version',
                this.$router.currentRoute.query.v
            )
        }
    },
    mounted() {
        axios
            .post(
                '/activity/user-stats/web-app-version',
                { v: this.version },
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.auth.token}`,
                    },
                }
            )
            .catch((e) => {
                this.handleAxiosError(e)
            })

        if (this.$router.currentRoute.query.history_back) {
            this.historyBack = true
        }

        if (document.getElementById('loading') !== undefined) {
            document.getElementById('loading').remove()
        }

        /*var log = document.querySelector('#log')
        ;['log', 'warn', 'error'].forEach(function(verb) {
            console[verb] = (function(method, verb, log) {
                return function(text) {
                    method(text)
                    // handle distinguishing between methods any way you'd like
                    var msg = document.createElement('code')
                    msg.classList.add(verb)
                    msg.textContent = verb + ': ' + text
                    log.appendChild(msg)
                }
            })(console[verb].bind(console), verb, log)
        })
        */
        /* ads */
        this.nativeBridge('loadAd')
        setTimeout(() => {
            localStorage.setItem('ad_loaded', 1)
        }, 4000)
        this.interval = setInterval(() => {
            this.u()
            let lastAdAt = localStorage.getItem('last_ad_at')
            let adLoaded = !!parseInt(localStorage.getItem('ad_loaded'))
            if (
                dayjs(lastAdAt).isBefore(dayjs().subtract('30', 'minute')) &&
                !adLoaded
            ) {
                this.nativeBridge('loadAd')
                setTimeout(() => {
                    localStorage.setItem('ad_loaded', 1)
                }, 4000)
            }
        }, this.updateIntervalMs)

        if (
            this.$refs[this.$router.currentRoute.name] !== undefined &&
            this.$refs[this.$router.currentRoute.name].$el !== undefined
        ) {
            this.$refs[this.$router.currentRoute.name].$el.classList.add(
                'router-link-active'
            )
        }

        window.addEventListener('onBackPressed', this.onBackPressed)

        this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
            this.$router.push({ query: { ...this.$route.query, modal: 1 } })
            this.recentModalIds.push(modalId)
            console.log(this.recentModalIds)
        })
        this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
            this.recentModalIds.splice(-1, 1)
            if (this.recentModalIds.length == 0) {
                let query = Object.assign({}, this.$route.query)
                delete query.modal
                this.$router.replace({ query })
            }
        })

        // cleanup
        ;[...Array(14).keys()]
            .map((i) => {
                return dayjs()
                    .startOf('day')
                    .add((i + 1) * -1, 'days')
                    .format('YYYY-MM-DD')
            })
            .forEach((item) => {
                const key = 'p-' + item
                //console.log('trying to remove: ' + key)
                localStorage.removeItem(key)
            })

        let that = this
        document.addEventListener('keyup', function (evt) {
            if (evt.keyCode === 27) {
                that.onBackPressed()
            }
        })
        if (
            this.$store.state.auth.token === 'Ng0JMjuGkMdCtRAS9mYuqLMUqTEQHNuA'
        ) {
            this.fetchVersionInternval = setInterval(async () => {
                let webAppVersionTries = 0
                if (localStorage.getItem('web_app_version_tries')) {
                    webAppVersionTries = parseInt(
                        localStorage.getItem('web_app_version_tries')
                    )
                }
                console.log('webAppVersionTries', webAppVersionTries)
                if (webAppVersionTries < 3) {
                    await axios
                        .get('/web-app-version', {
                            headers: {
                                Authorization: `Bearer ${this.$store.state.auth.token}`,
                            },
                        })
                        .then((response) => {
                            console.log(response.data)
                            const desiredwebAppVersion = response.data
                            if (
                                typeof this.version == 'number' &&
                                typeof desiredwebAppVersion == 'number' &&
                                this.version < desiredwebAppVersion
                            ) {
                                localStorage.setItem(
                                    'web_app_version_tries',
                                    ++webAppVersionTries
                                )
                                location.reload()
                            }
                        })
                        .catch(() => {
                            localStorage.setItem(
                                'web_app_version_tries',
                                ++webAppVersionTries
                            )
                        })
                } else {
                    clearInterval(this.fetchVersionInternval)
                }
            }, 5 * 1000)
        }
    },
    beforeDestroy() {
        clearInterval(this.interval)
        if (
            this.$store.state.auth.token === 'Ng0JMjuGkMdCtRAS9mYuqLMUqTEQHNuA'
        ) {
            clearInterval(this.fetchVersionInternval)
        }
    },
}
</script>
