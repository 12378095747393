import { mapGetters } from 'vuex'
import Broadcast from '../components/Broadcast'
import Loading from '../components/Loading'
import MediaLibraryItem from '../components/medialibrary/Item'
import ImageItem from '../components/ImageItem'
//import Payment from '../components/Payment'
import JoynModal from '../components/JoynModal.vue'
import axios from 'axios'
import { EventBus } from '@/event-bus'
export default {
    components: {
        Broadcast,
        Loading,
        MediaLibraryItem,
        ImageItem,
        JoynModal
        //Payment
    },
    data: function () {
        return {
            nowLoading: false,
            tonightLoading: false,
            topLoading: false,

            hMediaLibraryMovies:
                null || localStorage.getItem('hMediaLibraryMovies'),
            dayjs,
            banner: {
                rating: {
                    show: false,
                    action: {
                        a: false,
                        b: false,
                    },
                },
                megasim: {
                    show: false,
                    action: {
                        a: false
                    }
                },
                payment: {
                    show: false || !localStorage.getItem('banner-payment-hide') && localStorage.getItem('banner-rating-hide'),
                    action: {
                        a: false,
                    },
                },
            },
            modals: {
                worldtv: {
                    show: false,
                },
            },
            joynItems: [],
            joynWatchedItems: [],
            selectedMovie: {},
            selectedMovieModal: false,
        }
    },
    computed: {
        ...mapGetters('series', {
            broadcasts: 'upcomingBroadcasts',
        }),
        ...mapGetters('medialibrary', {
            movies: 'movies',
        }),
        todaysBroadcasts() {
            return this.broadcasts.filter(item =>
                dayjs(item.bc_start).isSame(new Date(), 'day')
            )
        },
    },
    methods: {
        handleJoynItemClick(item) {
            this.selectedMovie = item;
            this.selectedMovieModal = true;
            //this.startJoyn(item, 'movies');
        },
        worldTvOk(playstoreId, lang) {
            this.nativeBridge('openUrl', {
                url: `http://play.google.com/store/apps/details?id=${playstoreId}&referrer=utm_source%3D${process.env.VUE_APP_VARIANT}_${lang}`,
                mode: 'chrome',
            })
            this.modals.worldtv.show = false
            localStorage.setItem('hWorldTv', 1)
        },
        worldTvCancel() {
            this.modals.worldtv.show = false
            localStorage.setItem('hWorldTv', 1)
        },
        hideHint() {
            this.hMediaLibraryMovies = 1
            localStorage.setItem(
                'hMediaLibraryMovies',
                this.hMediaLibraryMovies
            )
        },
        fetchBroadcasts() {
            this.$store
                .dispatch('series/broadcasts', { today: true })
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => { })
        },
        fetchMovies() {
            this.$store
                .dispatch('medialibrary/movies')
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => { })
        },
        cc(val) {
            if (val == 'live') {
                this.nowLoading = true
            }
            if (val == 'p2015') {
                this.tonightLoading = true
            }
            if (val == 'recommendations') {
                this.topLoading = true
            }
            if (
                dayjs().isBetween(
                    dayjs()
                        .startOf('day')
                        .add(1, 'hours'),
                    dayjs()
                        .startOf('day')
                        .add(5, 'hours')
                )
            ) {
                val = 'program'
            }

            setTimeout(() => {
                this.$router.push({
                    name: val,
                    query: {
                        history_back: 1,
                    },
                })
            }, 100)
        },
        bannerAdClose() {
            this.banner.payment.show = false
            this.banner.payment.action.b = true
            localStorage.setItem('banner-payment-hide', true)
        },
        bannerAdActionA() {
            this.banner.payment.action.a = false
            EventBus.$emit('onPremiumRequested')
        },
        bannerRatingActionA() {
            this.banner.rating.show = false
            this.banner.rating.action.a = true
        },
        bannerRatingActionB() {
            this.banner.rating.show = false
            this.banner.rating.action.b = true
            localStorage.setItem('banner-rating-hide', true)
        },
        bannerRatingActionClose() {
            this.banner.rating.show = false
            this.banner.rating.action.a = false
            this.banner.rating.action.b = false
            localStorage.setItem('banner-rating-hide', true)
        },
        bannerRatingActionAAGaga() {
            this.banner.rating.show = false
            this.banner.rating.action.a = false
            this.banner.rating.action.b = false
            localStorage.setItem('banner-rating-hide', true)
            this.nativeBridge("openUrl", { url: "market://details?id=com.live.tv.kostenlos", mode: "chrome" });
            /* NOT WORKING ANYMORE 
            this.nativeBridge('openPlayStore', 'com.live.tv.kostenlos')
            */
        },
        bannerRatingActionAALive() {
            this.banner.rating.show = false
            this.banner.rating.action.a = false
            this.banner.rating.action.b = false
            localStorage.setItem('banner-rating-hide', true)

            this.nativeBridge("openUrl", { url: "market://details?id=com.tv.live.deutsches.fernsehen", mode: "chrome" });
            /* NOT WORKING ANYMORE 
            this.nativeBridge(
                'openPlayStore',
                'com.tv.live.deutsches.fernsehen'
            )*/
        },
        bannerRatingActionAALivePro() {
            this.banner.rating.show = false
            this.banner.rating.action.a = false
            this.banner.rating.action.b = false
            localStorage.setItem('banner-rating-hide', true)

            this.nativeBridge("openUrl", { url: "market://details?id=live.tv.app.pro", mode: "chrome" });
            /* NOT WORKING ANYMORE 
            this.nativeBridge(
                'openPlayStore',
                'com.tv.live.deutsches.fernsehen'
            )*/
        },
        bannerTariffActionA() {
            this.banner.megasim.action.a = true
            //this.nativeBridge('openUrl', { url: 'https://www.joyn.de/serien/steel-buddies-stahlharte-geschaefte', mode: 'gecko' })
            this.nativeBridge('startTV', {
                name: "name",
                title: "title",
                slug: "slug",
                gecko: true,
                url: 'https://www.tabletmessenger.com/ads/joyn.php?subid=j7sweiter',
                user_agent: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
                clear_cookies: true,
            })

            axios
                .post('/activity/banner/megasim', { v: 'click' }, {
                    headers: { Authorization: `Bearer ${this.$store.state.auth.token}` }
                })
                .then(response => {
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },
        bannerTariffActionClose() {
            this.banner.megasim.show = false
            localStorage.setItem('banner-megasim-hide', true)
            axios
                .post('/activity/banner/megasim', { v: 'close' }, {
                    headers: { Authorization: `Bearer ${this.$store.state.auth.token}` }
                })
                .then(response => {
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },

        // IN, JP, IT, AT, KR, FR, US, ES
        async getIpInfo() {
            return new Promise(async (resolve, reject) => {
                axios
                    .get('/ip')
                    .then(response => {
                        const debug = {
                            de: '57.83.40.0', //response.data.ip,
                            at: '2.16.10.255',
                            es: '2.16.8.0',
                            fr: '2.0.0.0',
                            in: '3.108.0.0',
                            it: '2.20.224.0',
                            jp: '1.21.0.0',
                            kr: '13.34.229.0',
                            us: '1.0.0.0',
                        }
                        const ip = response.data.ip // debug.de
                        const token = response.data.token

                        axios
                            .get(
                                `https://pro.ip-api.com/json/${ip}?key=${token}`
                            )
                            .then(response => {
                                const ipInfo = response.data
                                const countryCode = ipInfo.countryCode.toLowerCase()
                                if (countryCode != 'de') {
                                    this.modals.worldtv.show = true
                                    this.$i18n.locale = countryCode
                                }
                            })
                            .catch(console.error)
                    })
                    .catch(console.error)
            })
        },
    },
    mounted() {
        axios.get("/joyn/movies", {
            headers: { Authorization: `Bearer ${this.$store.state.auth.token}` }
        }).then(response => {
            this.joynItems = response.data
        }).catch(console.error)
        axios.get("/joyn/watched", {
            headers: { Authorization: `Bearer ${this.$store.state.auth.token}` }
        }).then(response => {
            this.joynWatchedItems = response.data
        }).catch(console.error)

        if (
            localStorage.getItem('hWorldTv') == null &&
            process.env.VUE_APP_VARIANT == 'livetv'
        ) {
            this.getIpInfo()
        }
        this.fetchMovies()
        this.fetchBroadcasts()
        if (localStorage.getItem('banner-rating-hide') == null) {
            this.banner.rating.show = true
        }
        if (localStorage.getItem('banner-megasim-hide') == null) {
            this.banner.megasim.show = true;
        }

        axios
            .post('/activity/router/today', {}, {
                headers: { Authorization: `Bearer ${this.$store.state.auth.token}` }
            })
            .catch(e => {
                this.handleAxiosError(e)
            })
    },
}
